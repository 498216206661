import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/414836623"
    bibleGroupSrc="https://www.docdroid.net/l0CSwnP/bible-group-homework-5-3-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="The Miracle of Protection - When Pigs Fly" />
  </Layout>
)

export default SermonPost
